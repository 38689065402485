import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getRouteToItem(item?: LibraryItem | CarouselItem) {
  // return "/overlays/4151";

  if (!item) {
    return "/library";
  }

  if ("url" in item) {
    return item.url;
  }

  if ("id" in item) {
    return `/library/items/${item.id}`;
  }

  if ("theme_id" in item) {
    return `/library/items/${item.theme_id}`;
  }

  return `/library`;

  // if (item.type === "overlay") {
  //   return `/library/overlays/${item.id}`;
  // } else if (item.type === "widget") {
  //   return `/library/widget-themes/${item.id}`;
  // } else if ("theme_id" in item) {
  //   return `/library/items/${item.theme_id}`;
  // }

  // return "/library";
}
